import React, { Component } from 'react';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import NewIcon from '@mui/icons-material/ModelTraining';
import ShareIcon from '@mui/icons-material/Twitter';
import RefreshIcon from '@mui/icons-material/Refresh';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import EmailIcon from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import '@fontsource/limelight';
import '@fontsource/josefin-slab';

const pitchesApi = 'https://kxxrfszxu6.execute-api.us-west-1.amazonaws.com/Integ/pitches';
const topPitchesApi = 'https://kxxrfszxu6.execute-api.us-west-1.amazonaws.com/Integ/pitches/top';
const sendEmailApi = 'https://c6na1q4bhh.execute-api.us-west-1.amazonaws.com/Integ/SendEmail';

const theme = createTheme({
  typography: {
    fontFamily: [
      // 'lora', 'georgia', 'serif',
      "josefin slab", "georgia", "serif"
      // 'limelight',
    ].join(','),
  },
});


class App extends Component {

  state = { 
    pitch: null,
    topPitchesThumbsUp: [false, false, false, false, false, false, false, false, false, false],
    topPitchesThumbsDown: [false, false, false, false, false, false, false, false, false, false],
    thumbsUp: false,
    thumbsDown: false,
    formCategory: '',
    formCategoryError: '',
    formPitchValue: '',
    formPitchValueError: '',
    senderName: '',
    senderNameError: '',
    senderEmail: '',
    senderEmailError: '',
    message: '',
    messageError: '',
    sumbitPitchSuccess: false,
    submitEmailSuccess: false,
  };

  componentDidMount() {
    this.generatePitch();
    this.listTopPitches();
  }

  generatePitch = () => {
    axios
      .get(pitchesApi)
      .then((response) => {
        this.setState({ 
          pitch: response.data,
          thumbsUp: false,
          thumbsDown: false
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  listTopPitches = () => {
    const reset = [false, false, false, false, false, false, false, false, false, false];
    axios
      .get(topPitchesApi)
      .then((response) => {
        this.setState({ 
          topPitches: response.data.Items,
          topPitchesThumbsUp: reset,
          topPitchesThumbsDown: reset
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  makeVotePitchCall = (pitchId, resource) => {
    axios
    .patch(pitchesApi + '/' + pitchId + '/' + resource)
    .then((response) => {
      // console.log(response)
    })
    .catch((error) => {
      // console.log(error);
    });
  };

  makeSubmitPitchCall = (category, value) => {
    axios
    .post(pitchesApi, {
      Category: category,
      Value: value
    })
    .then((response) => {
      this.setState({
        formCategory: '',
        formPitchValue: '',
        formCategoryError: '',
        formPitchValueError: '',
        sumbitPitchSuccess: true,
      })
    })
    .catch((error) => {
      // console.log(error);
    });
  };

  onSumbitPitch = () => {
    const category = this.state.formCategory;
    const value = this.state.formPitchValue;
    const requiredMessage = "Required field";

    if (!category || !value) {
      this.setState({
        formCategoryError: !category ? requiredMessage : '',
        formPitchValueError: !value ? requiredMessage : '',
      });
    } else {
      this.makeSubmitPitchCall(category, value);
    }
  };

  onUpdateThumbsUp = (indexToUpdate, value) => {
    this.setState(state => {
      const topPitchesThumbsUp = state.topPitchesThumbsUp.map((item, index) => {
        return index === indexToUpdate ? value : item;
      });

      return {
        topPitchesThumbsUp,
      };
    });
  };

  onUpdateThumbsDown = (indexToUpdate, value) => {
    this.setState(state => {
      const topPitchesThumbsDown = state.topPitchesThumbsDown.map((item, index) => {
        return index === indexToUpdate ? value : item;
      });

      return {
        topPitchesThumbsDown,
      };
    });
  };

  upVotePitch = (pitchId, index) => {
    if (pitchId == this.state.pitch.Id) {
      if (this.state.thumbsUp == false && this.state.thumbsDown == false) {
        this.setState({ 
          thumbsUp: true,
          thumbsDown: false
        });
        this.makeVotePitchCall(pitchId, 'upvote');
      } 
      else if (this.state.thumbsUp == true && this.state.thumbsDown == false) {
        this.setState({ 
          thumbsDown: false,
          thumbsUp: false
        });
        this.makeVotePitchCall(pitchId, 'downvote');
      } 
      else if (this.state.thumbsUp == false && this.state.thumbsDown == true) {
        this.setState({ 
          thumbsDown: false,
          thumbsUp: true
        });
        this.makeVotePitchCall(pitchId, 'upvote');
        this.makeVotePitchCall(pitchId, 'upvote');
      } 
    } else {
      if (this.state.topPitchesThumbsUp[index] == false && this.state.topPitchesThumbsDown[index] == false) {
        this.onUpdateThumbsUp(index, true);
        this.makeVotePitchCall(pitchId, 'upvote');
      } 
      else if (this.state.topPitchesThumbsUp[index] == true && this.state.topPitchesThumbsDown[index] == false) {
        this.onUpdateThumbsUp(index, false);
        this.makeVotePitchCall(pitchId, 'downvote');
      } 
      else if (this.state.topPitchesThumbsUp[index] == false && this.state.topPitchesThumbsDown[index] == true) {
        this.onUpdateThumbsUp(index, true);
        this.onUpdateThumbsDown(index, false);
        this.makeVotePitchCall(pitchId, 'upvote');
        this.makeVotePitchCall(pitchId, 'upvote');
      }
    }
  }

  downVotePitch = (pitchId, index) => {
    if (pitchId == this.state.pitch.Id) {
      if (this.state.thumbsUp == false && this.state.thumbsDown == false) {
        this.setState({ 
          thumbsUp: false,
          thumbsDown: true
        });
        this.makeVotePitchCall(pitchId, 'downvote');
      } 
      else if (this.state.thumbsUp == false && this.state.thumbsDown == true) {
        this.setState({ 
          thumbsDown: false,
          thumbsUp: false
        });
        this.makeVotePitchCall(pitchId, 'upvote');
      } 
      else if (this.state.thumbsUp == true && this.state.thumbsDown == false) {
        this.setState({ 
          thumbsDown: true,
          thumbsUp: false
        });
        this.makeVotePitchCall(pitchId, 'downvote');
        this.makeVotePitchCall(pitchId, 'downvote');
      }
    } else {
      if (this.state.topPitchesThumbsUp[index] == false && this.state.topPitchesThumbsDown[index] == false) {
        this.onUpdateThumbsDown(index, true);
        this.makeVotePitchCall(pitchId, 'downvote');
      } 
      else if (this.state.topPitchesThumbsUp[index] == false && this.state.topPitchesThumbsDown[index] == true) {
        this.onUpdateThumbsDown(index, false);
        this.makeVotePitchCall(pitchId, 'upvote');
      } 
      else if (this.state.topPitchesThumbsUp[index] == true && this.state.topPitchesThumbsDown[index] == false) {
        this.onUpdateThumbsDown(index, true);
        this.onUpdateThumbsUp(index, false);
        this.makeVotePitchCall(pitchId, 'downvote');
        this.makeVotePitchCall(pitchId, 'downvote');
      }
    }
  }

  handleShare = (pitch) => {
    const encodedAhref = encodeURIComponent(window.location.href);
    let text = `Would you watch this movie? ${pitch}. Created by the artificial intelligence @MovieBot_ai and ${encodedAhref}`;
    // window.open(`https://twitter.com/intent/tweet?url=${encodedAhref}?text=${text}`, '_blank');
    window.open(`https://twitter.com/intent/tweet?text=${text}`, '_blank');
  }

  handleNav = (navId) => {
    var element = document.getElementById(navId);
    var headerOffset = 120;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo( { behavior: 'smooth', top: offsetPosition } );
  };
  
  onFormCategoryChange = (event) => {
    this.setState({
      formCategory: event.target.value,
    });
  };

  onFormPitchValueChange = (event) => {
    this.setState({
      formPitchValue: event.target.value,
    });
  };

  onSenderEmailChange = (event) => {
    this.setState({
      senderEmail: event.target.value,
    });
  };

  onSenderNameChange = (event) => {
    this.setState({
      senderName: event.target.value,
    });
  };

  onMessageChange = (event) => {
    this.setState({
      message: event.target.value,
    });
  };

  onSubmitNFTRequest = () => {
    var senderEmail = this.state.senderEmail;
    var senderName = this.state.senderName;
    var message = this.state.message;
    var requiredMessage = "Required field";

    if (!senderEmail || !senderName || !message) {
      this.setState({
        senderEmailError: !senderEmail ? requiredMessage : '',
        senderNameError: !senderName ? requiredMessage : '',
        messageError: !message ? requiredMessage : '',
      });
    }
    else {
      axios
      .post(sendEmailApi, {
        SenderName: this.state.senderName,
        SenderEmail: this.state.senderEmail,
        Message: this.state.message
      })
      .then((response) => {
        this.setState({
          message: '',
          senderEmailError: '',
          senderNameError: '',
          messageError: '',
          submitEmailSuccess: true,
        })
      })
      .catch((error) => {
        // console.log(error);
      });
    }
  };

  onEmailUsClick = () => {
    const subject = "I need a movie idea!";
    const message = "";
    window.open(`mailto:hello@moviepitchbot.com?subject=${subject}&body=${message}`);
  };

  onTweetUsClick = () => {
    window.open(`https://twitter.com/MovieBot_ai`, '_blank');
  };

  handleSubmitPitchAlertClose = () => {
    this.setState({
      sumbitPitchSuccess: false,
    })
  };

  handleSubmitEmailAlertClose = () => {
    this.setState({
      submitEmailSuccess: false,
    })
  };

  render() {
    return (
      <div style={{ minWidth: 'fit-content', maxWidth: '100vw' }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Snackbar open={this.state.sumbitPitchSuccess} autoHideDuration={5000} onClose={this.handleSubmitPitchAlertClose}>
            <Alert onClose={this.handleSubmitPitchAlertClose} severity="success" sx={{ width: '100%' }}>
              Submitted your idea for consideration!
            </Alert>
          </Snackbar>

          <Snackbar open={this.state.submitEmailSuccess} autoHideDuration={5000} onClose={this.handleSubmitEmailAlertClose}>
            <Alert onClose={this.handleSubmitEmailAlertClose} severity="success" sx={{ width: '100%' }}>
              Thank you for your interest, we'll be in touch!
            </Alert>
          </Snackbar>

          <Box sx={{ flexGrow: 1 }}>
            <AppBar style={{position: 'fixed', backgroundColor: 'white', color: 'black', padding: 20 }}>
              <Toolbar>
                <PrecisionManufacturingOutlinedIcon size="large" edge="start" color="inherit" sx={{ mr: 2 }} />
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontFamily: 'limelight', fontWeight: '700', fontSize: '160%' }}>
                  M.P.B. (beta)
                </Typography>
                <Button color="inherit" onClick={() => this.handleNav('home')}>Home</Button>
                <Button color="inherit" onClick={() => this.handleNav('topPitches')}>Top Pitches</Button>
                <Button color="inherit" onClick={() => this.handleNav('submit')}>Submit Ideas</Button>
                <Button color="inherit" onClick={() => this.handleNav('nft')}>NFT Store</Button>
              </Toolbar>
            </AppBar>
          </Box>

          <Box sx={{ pt: 10 }}>
            <Box sx={{ backgroundColor: "#1c1c1c" }}>
            
              <Grid container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                sx={{pt: 5}}>
                <Grid item xs={6}>

                  <Typography  sx={{ lineHeight: 1.45, fontSize: 36, color: '#fff', textAlign: 'center', pt: 10, fontFamily: 'limelight' }} id="home">Hi, I'm Movie Pitch Bot</Typography>
                  <Typography  sx={{ lineHeight: 1.45, fontSize: 18, color: 'rgba(235,245,255,.5)', textAlign: 'center', padding: 7 }}
                  >I create movie pitch ideas. Let me know if you'd watch the movie below. Give it a thumbs up or down, 
                  or share it with a friend who would appreciate it. So, here's the pitch...</Typography>

                  <Box sx={{ display: 'flex', justifyContent: 'center', marginLeft: 15, marginRight: 15 }}>
                    <Card sx={{ width: 425, mt: 5 }}>
                      <CardContent>
                        <Typography variant="h5" color="text.secondary">
                          {this.state.pitch && this.state.pitch.Pitch}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <IconButton aria-label="up vote" onClick={() => this.upVotePitch(this.state.pitch ? this.state.pitch.Id : '')}>
                          {this.state.thumbsUp ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
                        </IconButton>
                        <IconButton aria-label="down vote" onClick={() => this.downVotePitch(this.state.pitch ? this.state.pitch.Id : '')}>
                          {this.state.thumbsDown ? <ThumbDownIcon /> : <ThumbDownOutlinedIcon />}
                        </IconButton>
                        <IconButton aria-label="share" onClick={() => this.handleShare(this.state.pitch ? this.state.pitch.Pitch : '')}>
                          <ShareIcon />
                        </IconButton>
                        <IconButton aria-label="new" onClick={this.generatePitch}>
                          <NewIcon />
                        </IconButton>
                      </CardActions>
                    </Card>
                  </Box>

                </Grid>
              </Grid>
            </Box>
            <svg width="100%" height="auto" viewBox="0 0 500 80" preserveAspectRatio="none">
              <path d="M0,0 L0,40 Q250,80 500,40 L500,0 Z" fill="#1c1c1c" />
            </svg>

            <Box>
              <Grid container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{pt: 5}}>
                <Grid item xs={6}>

                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography sx={{ lineHeight: 1.45, fontSize: 36, color: '#222', textAlign: 'center', fontFamily: 'limelight' }} id="topPitches">Top Movie Pitch Ideas</Typography>
                    <IconButton aria-label="refresh top pitches" onClick={this.listTopPitches}>
                      <RefreshIcon fontSize='inherit' color='text.primary' />
                    </IconButton>
                  </Box>

                  <Typography  sx={{ lineHeight: 1.45, fontSize: 24, color: '#222', textAlign: 'center', mb: 2 }}>All time top 5, as voted by users.</Typography>

                  {this.state.topPitches && this.state.topPitches.map((topPitch, index) => (
                   index < 5 &&
                    <React.Fragment key={topPitch.Id}>
                      <Card sx={{ width: 425 }}>
                        <CardContent>
                          <Typography variant="h5" color="text.secondary">
                            {topPitch.Pitch}
                          </Typography>
                          <Typography variant="h6" color="text.tertiary">
                            Rating: {topPitch.Rating}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <IconButton aria-label="up vote" onClick={() => this.upVotePitch(topPitch.Id, index)}>
                            {this.state.topPitchesThumbsUp[index] ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
                          </IconButton>
                          <IconButton aria-label="down vote" onClick={() => this.downVotePitch(topPitch.Id, index)}>
                            {this.state.topPitchesThumbsDown[index] ? <ThumbDownIcon /> : <ThumbDownOutlinedIcon />}
                          </IconButton>
                          <IconButton aria-label="share" onClick={() => this.handleShare(topPitch.Pitch)}>
                            <ShareIcon />
                          </IconButton>
                        </CardActions>
                      </Card>
                      <br/>
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ backgroundColor: "#1c1c1c", pb: 5 }}>
              <Grid container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                sx={{pt: 5}}>
                <Grid item xs={6}>

                  <Typography  sx={{ lineHeight: 1.45, fontSize: 36, color: '#e8eaec', textAlign: 'center', fontFamily: 'limelight' }} id="submit">Submit your own ideas...</Typography>
                  <Typography  sx={{ lineHeight: 1.45, fontSize: 24, color: '#ececec', textAlign: 'center' }}>Submit ideas for stars, settings, etc., and our AI will consider them next time.</Typography>
                  
                  <Box component="form" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', '& .MuiOutlinedInput-notchedOutline': {borderColor: 'white', opacity: '70%'}, '& option:active': {borderColor: 'white'}, '& .MuiTextField-root': { m: 1, width: 400 }, '& .MuiOutlinedInput-root': {'&.Mui-focused fieldset': {borderColor: 'white' }, '&:hover fieldset': {borderColor: 'white' }} }}>
                    <Select id="sumbit-category" required error={!!this.state.formCategoryError} helpertext={this.state.formCategoryError} value={this.state.formCategory} label="Category" onChange={this.onFormCategoryChange} sx={{ color: 'white', m: 1, width: 400, "& option": {color: 'white'} }}>
                      <MenuItem value={'GENRE'}>Genre</MenuItem>
                      <MenuItem value={'SETTING'}>Setting</MenuItem>
                      <MenuItem value={'STAR'}>Star</MenuItem>
                      <MenuItem value={'STORY'}>Story</MenuItem>
                      <MenuItem value={'STYLE'}>Style</MenuItem>
                    </Select>
                    <TextField required error={!!this.state.formPitchValueError} helperText={this.state.formPitchValueError} value={this.state.formPitchValue} onChange={this.onFormPitchValueChange} id="contact-pitch" label="Your Idea" variant="outlined" multiline rows={4} floatinglabelstyle={{color: 'white'}} sx={{'& .MuiFormLabel-root': {color: 'white', '&:hover': {color: 'white'}}, '&.MuiInputBase-root': { color: 'white' },  '& .MuiFormLabel-root.Mui-focused': {color: 'white'} }} inputProps={{ style: { color: "white" } }} />
                    <Button variant="contained" sx={{ background: "#c23638", borderRadius: "1000px", fontFamily: "lora, georgia, serif", textTransform: 'none', fontSize: "20px", "&:hover": { background: '#e84043' }}} onClick={this.onSumbitPitch}>Submit</Button>
                  </Box>

                </Grid>
              </Grid>
            </Box>

            <Box sx={{ pt: 5, pb: 5 }} >

              <Typography sx={{ lineHeight: 1.45, fontSize: 36, color: '#222', textAlign: 'center', fontFamily: 'limelight' }} id="nft">Order your 1 of a kind NFT movie poster</Typography>
              <Typography  sx={{ lineHeight: 1.45, fontSize: 24, color: '#c23638', textAlign: 'center', mb: 2 }}>Contact us below with the movie pitch you want, and we will be in touch.</Typography>

              <Box component="form" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', '& .MuiTextField-root': { m: 1, width: 400 } }}>
                <TextField id="contact-name" required error={!!this.state.senderNameError} helperText={this.state.senderNameError} label="Name" variant="outlined" value={this.state.senderName} onChange={this.onSenderNameChange} />
                <TextField id="contact-email" required error={!!this.state.senderEmailError} helperText={this.state.senderEmailError} label="Email" variant="outlined" value={this.state.senderEmail} onChange={this.onSenderEmailChange} />
                <TextField id="contact-message" required error={!!this.state.messageError} helperText={this.state.messageError} label="Message" variant="outlined" multiline rows={4} value={this.state.message} onChange={this.onMessageChange} />
                <Button variant="contained" onClick={this.onSubmitNFTRequest} sx={{ background: "#c23638", borderRadius: "1000px", fontFamily: "lora, georgia, serif", textTransform: 'none', fontSize: "20px", "&:hover": { background: '#e84043' } }}>Submit</Button>
              </Box>

            </Box>

            <Box sx={{ pt: 5, pb: 5 }} >

              <Typography sx={{ lineHeight: 1.45, fontSize: 36, color: '#222', textAlign: 'center', fontFamily: 'limelight' }} id="follow us">Follow us</Typography>
              <Typography  sx={{ lineHeight: 1.45, fontSize: 24, color: '#c23638', textAlign: 'center', mb: 2 }}>You know you need more movie ideas...</Typography>

              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', '& .MuiButtonBase-root': { m: 1 } }}>
                <IconButton onClick={this.onEmailUsClick} variant="contained" aria-label="email-us" sx={{ backgroundColor: 'black', width: 160, height: 160, "&:hover": { background: 'black', opacity: '70%' } }} >
                  <EmailIcon sx={{ color: 'white', width: 80, height: 80 }} />
                </IconButton>
                <IconButton onClick={this.onTweetUsClick} variant="contained" aria-label="tweet-us" sx={{ backgroundColor: 'black', width: 160, height: 160, "&:hover": { background: 'black', opacity: '70%' } }} >
                  <TwitterIcon sx={{ color: 'white', width: 80, height: 80 }} />
                </IconButton>
              </Box>

            </Box>

            <Box sx={{ backgroundColor: "#1c1c1c", p: 2 }}>
              <Typography sx={{ color: 'white' }}>MPB © 2023</Typography>
            </Box>

          </Box>
        </ThemeProvider>
      </div>
    );
  }
}

export default App;
